<template>
  <v-dialog
    v-model="dialog"
    scrollable
    persistent
    max-width="1800px"
    max-height="1000px"
    @click:outside="onCancel"
  >
    <v-card>
      <v-card-title class="mb-5">
        <span class="headline font-weight-bold primary--text"
          >Gerber Upload</span
        >
      </v-card-title>
      <v-card-text>
        <v-row dense>
          <v-col cols="12">
            <v-sheet :color="dragIn ? 'alert' : 'primary'" dark outlined shaped>
              <div
                v-if="!gerber_files && gerberFiles.length === 0"
                @dragover="dragover"
                @dragleave="dragleave"
                @drop="drop"
                class="pa-10"
              >
                <input
                  type="file"
                  style="display: none"
                  name="fields[assetsFieldHandle][]"
                  id="assetsFieldHandle"
                  @change="onConvert"
                  ref="file"
                  accept=".zip"
                />
                <label for="assetsFieldHandle" class="fieldLink">
                  <v-row dense>
                    <v-col class="d-flex justify-center">
                      <v-icon x-large>
                        mdi-cloud-upload
                      </v-icon>
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col class="d-flex justify-center">
                      <h1 class="mt-2 pa-1 font-weight-bold">
                        Drag and drop your zipped Gerber files
                      </h1>
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col class="d-flex justify-center">
                      <p>
                        Please only upload gerbers of the board and keep
                        documentation in separate layer files.
                      </p>
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col class="d-flex justify-center">
                      <h4>
                        Upload gerber files for a single unit. Contact
                        <a
                          class="white--text"
                          href="mailto:support@fixturfab.com"
                          >support@fixturfab.com</a
                        >
                        if you are interested in a panel fixture.
                      </h4>
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col class="d-flex justify-center">
                      <h3 class="my-3">
                        Or you may
                        <span class="font-weight-light">click here</span> to
                        upload your zipped Gerber files
                      </h3>
                    </v-col>
                  </v-row>
                </label>
              </div>
              <div v-else class="pa-10">
                <v-row dense>
                  <v-col class="d-flex justify-center">
                    <v-icon x-large>
                      mdi-cloud-upload
                    </v-icon>
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col class="d-flex justify-center">
                    <h1 class="mt-2 pa-1 font-weight-bold">
                      {{
                        gerber_files
                          ? gerber_files.name
                          : "Currently saved Gerber Files"
                      }}
                    </h1>
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col class="d-flex justify-center">
                    <div class="pa-1">
                      Saving the gerber files FixturFab will use to design your
                      fixture.
                    </div>
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col class="d-flex justify-center">
                    <v-btn text @click="initializeLists"
                      >Remove all files</v-btn
                    >
                  </v-col>
                </v-row>
              </div>
            </v-sheet>
          </v-col>
        </v-row>
        <v-sheet outlined class="my-2" v-if="artFiles && artFiles.length > 0">
          <v-container class="warning--text">
            <div class="text-center">
              FixturFab is not compatible with .art gerbers, we detected the
              following in your zipped dir.
            </div>
            <div class="text-center mb-2">
              You can rename them to the
              <a
                target="_blank"
                href="https://tinymicros.com/wiki/Protel_Gerber_Layer_Names"
                >Protel Gerber Layer names</a
              >
              and re-upload or provide us with ODB++ or IPC2581 files instead of
              gerbers.
            </div>
            <base-scroll-file-list :fileList="artFiles" />
          </v-container>
        </v-sheet>
        <v-sheet
          outlined
          class="my-2"
          v-if="otherFiles && otherFiles.length > 0"
        >
          <v-container>
            <v-row dense>
              <v-col cols="12" class="d-flex justify-center warning--text my-2">
                <span
                  >FixturFab detected the following valid Gerber files. However,
                  we will only use the top and bottom layer Gerber files.</span
                >
              </v-col>
            </v-row>
            <base-scroll-file-list :fileList="otherFiles" />
          </v-container>
        </v-sheet>
        <v-sheet
          outlined
          class="my-2"
          v-if="invalidGerbers && invalidGerbers.length > 0 && !isLoading"
        >
          <v-container>
            <v-row dense>
              <v-col cols="12" class="d-flex justify-center warning--text my-2">
                <span>Timed out while parsing the following Gerber files.</span>
              </v-col>
            </v-row>
            <base-scroll-file-list :fileList="invalidGerbers" />
          </v-container>
        </v-sheet>
        <div v-if="gerberFiles && gerberFiles.length > 0">
          <v-row>
            <v-col>
              <div v-for="(side, index) in gerberFiles" :key="index">
                <v-sheet dark outlined color="info" class="mt-5">
                  <v-row dense>
                    <v-col class="d-flex justify-center">
                      {{ side.side ? side.side.toUpperCase() : "" }}
                    </v-col>
                  </v-row></v-sheet
                >
                <v-slide-group
                  class="pa-4"
                  style="backgroundColor: #F5F5F5"
                  center-active
                  show-arrows
                >
                  <v-slide-item
                    v-for="(item, index) in side.data"
                    :key="index"
                    v-slot="{ active, toggle }"
                  >
                    <v-card
                      outlined
                      :dark="active"
                      class="mx-auto"
                      :color="active ? 'secondary' : ''"
                      @click="toggle"
                    >
                      <v-card-text>
                        <div v-html="item.svg" />
                        <v-row dense>
                          <v-col>
                            <v-list-item class="pa-0">
                              <v-list-item-content class="pa-0">
                                <v-list-item-title>
                                  {{ item.filename }}</v-list-item-title
                                >
                                <v-list-item-subtitle>{{
                                  item.type
                                }}</v-list-item-subtitle>
                              </v-list-item-content>
                              <v-tooltip top>
                                <template v-slot:activator="{ on }">
                                  <v-btn
                                    icon
                                    color="primary"
                                    @click.stop="
                                      removeGerberHandler({ side, index })
                                    "
                                    v-on="on"
                                    ><v-icon>mdi-close</v-icon></v-btn
                                  >
                                </template>
                                <span>Remove this Gerber</span>
                              </v-tooltip>
                            </v-list-item>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-slide-item>
                </v-slide-group>
              </div>
            </v-col>
          </v-row>
        </div>
        <v-container
          v-if="gerberFiles.length === 0 && gerber_files && !isLoading"
        >
          <v-row dense>
            <v-col cols="12" class="d-flex justify-center">
              <span class="subtitle-1 font-weight-bold warning--text"
                >No valid Gerber files detected</span
              >
            </v-col>
          </v-row>
          <v-row dense v-if="dirFolders && dirFolders.length > 1">
            <v-col cols="12" class="d-flex justify-center">
              <span class="subtitle-1 primary--text"
                >Upload a zip file of a single directory containing the
                Gerbers.</span
              >
            </v-col>
          </v-row>
        </v-container>
        <v-divider />
        <v-card-actions class="ma-2">
          <v-row v-if="gerberFiles.length > 0">
            <v-col class="d-flex justify-center">
              <v-btn color="warning" class="mx-auto" @click="onCancel"
                >Cancel</v-btn
              >
              <v-btn color="primary" class="mx-auto" @click="onSave"
                >Save</v-btn
              >
            </v-col>
          </v-row>
          <v-row v-else>
            <v-col class="d-flex justify-center">
              <v-btn color="warning" @click="onClose">Close</v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import JSZip from "jszip";
import gerberToSvg from "gerber-to-svg";
import whatsThatGerber from "whats-that-gerber";
import { mapGetters, mapActions } from "vuex";
export default {
  name: "FileUploadModal",
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    gerbers: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      gerber_files: null,
      dragIn: false,
      isLoading: false,
      artFiles: null,
      dirFolders: null,
      otherFiles: null,
      invalidGerbers: [],
      MAXIMUM_SECONDS_PARSE_LONG: 1e4,
      MAXIMUM_SECONDS_TO_PARSE: 12e4,
    };
  },
  computed: {
    ...mapGetters({
      zipGerberFiles: "dutconfigs/zipGerberFiles",
      uiLoading: "ui/loading",
    }),
    gerberFiles() {
      return [...this.zipGerberFiles].sort((a, b) =>
        a.side > b.side ? 1 : -1
      );
    },
    savedGerbers() {
      return this.gerbers && this.gerbers.length > 0
        ? this.gerbers.map((element) => ({
            pk: element.pk,
            url: element.file,
            file: element.file.split("/").pop(),
          }))
        : null;
    },
  },
  methods: {
    ...mapActions({
      toggleLoading: "ui/loading",
    }),
    dragover(event) {
      event.preventDefault();
      this.dragIn = true;
    },
    dragleave(event) {
      event.preventDefault();
      this.dragIn = false;
    },
    drop(event) {
      event.preventDefault();
      this.$refs.file.files = event.dataTransfer.files;
      this.gerber_files = this.$refs.file.files;
      this.onConvert();
      this.dragIn = false;
    },
    initializeLists() {
      this.gerber_files = this.artFiles = this.dirFolders = this.otherFiles = null;
      this.invalidGerbers.splice(0);
      this.$store.commit("dutconfigs/INIT_ZIP_GERBER_FILES");
    },
    onClose() {
      this.initializeLists();
      this.$emit("closeModal");
    },
    onCancel() {
      if (this.isLoading) return;
      this.initializeLists();
      this.$emit("closeModal");
    },
    async onConvert() {
      this.gerber_files = this.$refs.file.files[0];
      if (!this.gerber_files) {
        this.$store.commit("dutconfigs/INIT_ZIP_GERBER_FILES");
        return;
      }
      try {
        const zip = new JSZip();
        const response = await zip.loadAsync(this.gerber_files);
        const validFiles = Object.keys(response.files).reduce(
          (reducer, element) => {
            if (element.split("/").pop().length === 0) {
              reducer.dir.push(element);
            } else if (element.split(".").pop() !== "art") {
              reducer.mapped.push(element);
            } else {
              reducer.art.push(element);
            }
            return reducer;
          },
          { dir: [], mapped: [], art: [], other: [] }
        );
        this.artFiles = validFiles.art;
        this.dirFolders = validFiles.dir;
        this.toggleLoading("Parsing Gerber Files");
        this.isLoading = true;
        const tsBefore = new Date();
        let loaderUpdated = false;
        for (let i = 0; i < validFiles.mapped.length; i++) {
          const tsAfter = new Date();
          if (
            !loaderUpdated &&
            tsAfter - tsBefore >= this.MAXIMUM_SECONDS_PARSE_LONG
          ) {
            this.toggleLoading();
            this.toggleLoading(
              "Still parsing your gerbers, they might be too large"
            );
            loaderUpdated = true;
          }
          const fileObj = validFiles.mapped[i];
          const filename = fileObj.split("/").pop();
          const content = await zip.file(fileObj).async("nodebuffer");
          const layerObj = whatsThatGerber([filename]);
          if (
            layerObj[filename].side == "top" ||
            layerObj[filename].side == "bottom" ||
            (layerObj[filename].side == "all" &&
              filename
                .split(".")
                .pop()
                .toLowerCase() != "gko")
          ) {
            await this.parserWithTimeLimit({
              timeLimit: this.MAXIMUM_SECONDS_TO_PARSE,
              payload: {
                filename,
                content,
                gerberSide: layerObj[filename].side,
                gerberType: layerObj[filename].type,
              },
            });
          } else {
            validFiles.other.push(validFiles.mapped[i]);
          }
        }
        this.toggleLoading();
        this.isLoading = false;
        this.otherFiles = validFiles.other;
      } catch (err) {
        if (this.uiLoading.status) {
          this.toggleLoading();
          this.isLoading = false;
        }
        if (this.zipGerberFiles.length > 0) this.initializeLists();
        this.$store.commit(
          "ui/SNACK_BAR",
          err.message.split(":").pop() || "Only zip files can be uploaded."
        );
      }
    },
    gerberSvgHandler({
      gerberPk = null,
      filename,
      content,
      gerberSide,
      gerberType,
    }) {
      return new Promise((resolve, reject) => {
        gerberToSvg(content, "", (error, svg) => {
          if (error) {
            reject("gerber to svg error - " + error.message);
          }
          const width = new DOMParser()
            .parseFromString(svg, "image/svg+xml")
            .documentElement.getAttribute("width");
          const height = new DOMParser()
            .parseFromString(svg, "image/svg+xml")
            .documentElement.getAttribute("height");
          const actionPayload = {
            valid:
              +width + +height !== 0 &&
              gerberSide &&
              gerberType &&
              (gerberSide == "top" ||
                gerberSide == "bottom" ||
                gerberSide == "all")
                ? true
                : false,
            payload: {
              side:
                gerberSide == "top"
                  ? "Top Layer"
                  : gerberSide == "bottom"
                  ? "Bottom Layer"
                  : "Mechanical Layers",
              data: [
                {
                  pk: gerberPk,
                  filename,
                  gerber: content,
                  svg: svg,
                  type: gerberType,
                },
              ],
            },
          };
          this.$store.dispatch("dutconfigs/saveZipGerberFiles", actionPayload);
          resolve(svg);
        });
      });
    },
    async parserWithTimeLimit({ timeLimit, payload }) {
      let timeout;
      const timeoutPromise = new Promise((resolve) => {
        timeout = setTimeout(() => {
          resolve(null);
        }, timeLimit);
      });

      const response = await Promise.race([
        this.gerberSvgHandler(payload),
        timeoutPromise,
      ]);
      !response && this.invalidGerbers.push(payload.filename);
      timeout && clearTimeout(timeout);
    },
    onSave() {
      if (
        (!this.zipGerberFiles || this.zipGerberFiles.length === 0) &&
        this.gerbers.length === 0
      )
        return;

      if (this.gerber_files) {
        const actionPayload = this.zipGerberFiles.reduce((mapped, element) => {
          mapped.push(...element.data);
          return mapped;
        }, []);
        this.$emit("saveGerbers", {
          actionPayload,
          zipped_file: this.gerber_files,
        });
      } else {
        this.$emit("closeModal");
      }
      this.initializeLists();
    },
    rowColor(index) {
      return index % 2 !== 0 ? "#F5F5F5" : "white";
    },
    removeGerberHandler({ side, index }) {
      const record = this.gerberFiles.find(
        (element) => element.side == side.side
      );
      if (record) {
        record["data"].splice(index, 1);
      }
      if (record && record["data"].length == 0) {
        this.gerberFiles.splice(this.gerberFiles.indexOf(record), 1);
      }
    },
    async loadSavedGerbers() {
      if (!this.savedGerbers) return;
      this.toggleLoading("Parsing saved Gerber Files");
      this.$store.commit("dutconfigs/INIT_ZIP_GERBER_FILES");
      this.isLoading = true;
      const tsBefore = new Date();
      let loaderUpdated = false;
      for (let i = 0; i < this.savedGerbers.length; i++) {
        const tsAfter = new Date();
        if (!loaderUpdated && tsAfter - tsBefore >= 10000) {
          this.toggleLoading();
          this.toggleLoading(
            "Still parsing your gerbers, they might be too large"
          );
          loaderUpdated = true;
        }
        const fileObj = this.savedGerbers[i].url;
        const filename = this.savedGerbers[i].file;
        const gerberPk = this.savedGerbers[i].pk;
        const content = await this.getFileString(fileObj);
        const layerObj = whatsThatGerber([filename]);
        console.log(filename, layerObj);
        if (
          layerObj[filename].side == "top" ||
          layerObj[filename].side == "bottom" ||
          (layerObj[filename].side == "all" &&
            filename
              .split(".")
              .pop()
              .toLowerCase() != "gko")
        ) {
          await this.gerberSvgHandler({
            gerberPk,
            filename,
            content,
            gerberSide: layerObj[filename].side,
            gerberType: layerObj[filename].type,
          });
        }
      }
      this.isLoading = false;
      this.toggleLoading();
    },
    async getFileString(payload) {
      try {
        const data = await fetch(payload);
        const svgString = await data.text();
        return svgString;
      } catch (err) {
        if (this.uiLoading.status) {
          this.toggleLoading();
          this.isLoading = false;
        }
        this.$store.commit("ui/SNACK_BAR", err);
      }
    },
  },
  async mounted() {
    this.dragIn = false;
    this.isLoading = false;
    this.initializeLists();
    await this.loadSavedGerbers();
  },
};
</script>
<style scoped>
.verticalScroll {
  overflow-y: scroll;
}
</style>
